import './App.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import React from 'react';

const App = () => {
	const location = useLocation();
	const [urlCerberus, setUrlCerberus] = useState('')
	let queryParams = new URLSearchParams(location.search);
	let userCpf = queryParams.get('cpf');

	useEffect(() => {
		axios.get(`https://nyccerberusservice.azurewebsites.net/api/v2/Cerberus/Token/GetTokenOnBoardingUrl/${userCpf}`)
			.then((res) => setUrlCerberus(res.data))
	}, [])
	return (
		<div className="App">
			<iframe src={urlCerberus} allow="camera" style={{ height: "100vh", width: "100vw", border: "none", }} allowFullScreen           ></iframe>
		</div>
	);
}

export default App;
